<template>
  <div>
    <v-card class="mb-4 elevation-0">
      <v-card-text>
        <h1>Notifikasi</h1>
        <p>Halaman untuk melihat semua notifikasi</p>
        <div class="d-flex justify-space-between">
          <v-row>
            <v-col cols="7">
              <v-row>
                <v-col cols="5">
                  <div>
                    <v-autocomplete
                      v-model="orderBy"
                      label="Urutkan Dari"
                      outlined
                      dense
                      :items="orderByOptions"
                      item-text="text"
                      item-value="value"
                    >
                    </v-autocomplete>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="4"
              offset-lg="1"
            >
              <div>
                <v-text-field
                  v-model="search"
                  outlined
                  dense
                  placeholder="Cari"
                >
                </v-text-field>
              </div>
            </v-col>
          </v-row>
        </div>
        <v-row
          justify="space-between"
          align="center"
          align-content="center"
        >
          <v-col>
            <v-checkbox
              v-model="selectAll"
              label="Pilih Semua"
            ></v-checkbox>
          </v-col>
          <v-col>
            <v-row
              justify="end"
              align="center"
              align-content="center"
            >
              <v-btn
                color="primary"
                class="mr-3"
                :loading="isLoadingRead"
                :disabled="isLoadingRead"
                @click="handleReadAll"
              >
                Baca Semua
              </v-btn>
              <v-btn
                color="error"
                outlined
                class="mr-3"
                :loading="isLoadingDelete"
                :disabled="isLoadingDelete || selectedNotif.length < 1"
                @click="handleDeleteSelected"
              >
                Hapus Terpilih
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
        <v-progress-linear
          v-if="isLoadingList"
          indeterminate
          color="primary"
        ></v-progress-linear>
        <template v-if="notifications.length > 0">
          <CardNotification
            v-for="(notif) in notifications"
            :key="notif.uuid"
            :uuid="notif.uuid"
            :title="notif.title"
            :content="notif.body"
            :selected.sync="notif.selected"
            :read-at.sync="notif.read_at"
            :date="formatedDate(notif.created_at)"
            class="mb-4"
            @select="handleSelectNotif"
            @read="handleReadNotif"
          ></CardNotification>
        </template>
        <v-card v-intersect="onScrollEnd"></v-card>
        <div class="text-center">
          <p
            v-if="notifications.length < 1 && !isLoadingData"
          >
            Belum ada Notifikasi
          </p>
          <v-progress-circular
            v-if="isLoadingData"
            :size="50"
            color="primary"
            indeterminate
            class="m-auto"
          ></v-progress-circular>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import CardNotification from '@/views/components/CardNotification.vue'
import dateTimeFormat from '@/utils/date/dateTimeFormat'

export default {
  name: 'Notification',
  totalNotif: 0,
  perPage: 15,
  isLoadingData: false,
  components: {
    CardNotification,
  },
  data() {
    return {
      timeout: null,
      searchDebounce: '',
      isLoadingData: false,
      isLoadingList: false,
      isLoadingRead: false,
      isLoadingDelete: false,
      notifications: [],
      page: 1,
      selectedNotif: [],
      selectAll: false,
      orderBy: 'DESC',
      orderByOptions: [
        {
          text: 'Terbaru',
          value: 'DESC',
        },
        {
          text: 'Terlama',
          value: 'ASC',
        },
      ],
    }
  },
  computed: {
    search: {
      get() {
        return this.searchDebounce
      },
      set(val) {
        if (val.length > 3 || val.length === 0) {
          if (this.timeout) clearTimeout(this.timeout)
          this.timeout = setTimeout(async () => {
            this.searchDebounce = val
            this.isLoadingList = true
            await this.listNotif({ notification: this.searchDebounce })
            this.isLoadingList = false
          }, 300)
        }
      },
    },
  },
  watch: {
    selectAll() {
      if (this.selectAll) {
        this.handleSelectAll()
      } else {
        this.handleUnselectAll()
      }
    },
    async orderBy() {
      this.isLoadingList = true
      await this.listNotif()
      this.isLoadingList = false
    },
  },
  async mounted() {
    this.isLoadingData = true
    await this.listNotif()
    this.isLoadingData = false
  },
  methods: {
    async listNotif(params = {}) {
      await this.$services.NotificationsServices.list({
        ...params,
        page: this.page,
        order_by: this.orderBy,
      }).then(
        ({ data }) => {
          const notifications = data.data.map(item => {
            item.selected = false

            return item
          })
          this.notifications = [...notifications]
          this.totalNotif = data.meta.totalNotif
          this.perPage = data.meta.per_page
        },
        err => console.error(err),
      )
    },
    formatedDate(time) {
      return dateTimeFormat(time)
    },
    onScrollEnd(entries, observer) {
      if (entries[0].isIntersecting && this.totalNotif > this.perPage && this.notifications.length < this.totalNotif) {
        setTimeout(() => {
          this.isLoadingData = true
        }, 300)
        this.page += 1
        this.$services.NotificationsServices.list({ page: this.page }).then(
          ({ data }) => {
            if (data.data.length > 0) {
              this.notifications = [...this.notifications, ...data.data]
            }
          },
          err => console.error(err),
        )
        this.isLoadingData = false
      }
    },
    handleSelectNotif(data) {
      if (!data.isSelected) {
        this.selectedNotif = this.selectedNotif.filter(item => item !== data.uuid)
      } else {
        this.selectedNotif = [...this.selectedNotif, data.uuid]
      }
      this.notifications.forEach(item => {
        if (item.uuid === data.uuid) item.selected = !item.selected
      })
    },
    async handleReadNotif(args) {
      await this.$services.NotificationsServices.update({ read_at: new Date() }, args.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: 'Sucess Read Notification',
            color: 'success',
          })
          args.onSuccess()
        },
        err => {
          console.error(err)
          args.onFailure()
        },
      )
    },
    handleSelectAll() {
      this.selectedNotif = []
      const list = this.notifications.map(item => ({ ...item, selected: true }))
      this.notifications = [...list]
    },
    handleUnselectAll() {
      this.selectedNotif = []
      const list = this.notifications.map(notif => {
        notif.selected = false

        return { ...notif }
      })
      this.notifications = []
      setTimeout(() => {
        this.notifications = [...list]
      }, 1)
    },
    async handleReadAll() {
      this.isLoadingRead = true
      const unReadNotif = this.notifications.filter(item => !item.read_at)
      this.notifications = []
      this.isLoadingData = true
      await unReadNotif.forEach(async item => {
        await this.$services.NotificationsServices.update({ read_at: new Date() }, item.uuid).then(
          ({ data }) => {},
          err => console.error(err),
        )
      })
      await this.listNotif({ per_page: this.notifications.length })
      this.isLoadingRead = false
      this.isLoadingData = false
    },
    async handleDeleteSelected() {
      this.isLoadingDelete = true
      this.isLoadingList = true
      await this.selectedNotif.forEach(async item => {
        await this.$services.NotificationsServices.destroy(item).then(
          ({ data }) => {},
          err => console.error(err),
        )
      })
      await this.listNotif()
      this.isLoadingDelete = false
      this.isLoadingList = false
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style>
</style>
